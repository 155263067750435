import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import deTranslation from './locales/de/translation.json'
import enTranslation from './locales/en/translation.json'
import esTranslation from './locales/es/translation.json'
import fiTranslation from './locales/fi/translation.json'
import frTranslation from './locales/fr/translation.json'
import itTranslation from './locales/it/translation.json'
import nb_NOTranslation from './locales/nb_NO/translation.json'
import zhTranslation from './locales/zh/translation.json'
import roTranslation from './locales/ro/translation.json'
import jaTranslation from './locales/ja/translation.json'

const enableDebug = process.env.NODE_ENV === 'production'

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: enableDebug,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'lng',
    },

    resources: {
      de: {
        translation: deTranslation,
      },
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      fi: {
        translation: fiTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      it: {
        translation: itTranslation,
      },
      nb_NO: {
        translation: nb_NOTranslation,
      },
      zh: {
        translation: zhTranslation,
      },
      ro: {
        translation: roTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
    },
  })

export default i18n
