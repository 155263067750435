import * as yup from "yup";

const CreateSurveySchema = yup.object().shape({
  inputCode: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, "Please remove whitespace")
    .min(1),
});

export default CreateSurveySchema;
