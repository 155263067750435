import React, {Fragment, useEffect, useState} from 'react'
import {IDemogField} from '../types'
import queryString from 'query-string'
import DemogInputSearch from './DemogInputSearch'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

interface IRecipientSearchFormProps {
  demogFields: IDemogField[]
  recipientSearch: (searchData: string) => Promise<void>
}

export const RecipientSearchForm: React.FC<IRecipientSearchFormProps> = ({demogFields, recipientSearch}) => {
  const stateObject: any = {}
  demogFields.forEach(demogField => {
    stateObject[demogField.id] = ''
  })

  const {t} = useTranslation()

  const [data, setData] = useState(stateObject)

  const demogNameToId = (demogName: string) => {
    return demogName.toLowerCase().replace(/ /gm, '')
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const stringifyQuery = queryString.stringify(data)

    recipientSearch(stringifyQuery)
  }

  const handleFormChange = (value: string | number | boolean, demogField: any) =>
    setData({...data, [demogField.id]: value})

  let formInvalid = false
  demogFields.forEach(demogField => {
    if (!data[demogField.id]) {
      formInvalid = true
    }
  })

  return (
    <div className="form__wrapper">
      <h5 className="mb-16">{t('enterDemogs.heading')}</h5>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        {demogFields.map((demogField, i: number) => {
          return (
            <Fragment key={i}>
              <DemogInputSearch
                id={demogNameToId(demogField.name)}
                labelText={
                  demogField.format.toLowerCase() === 'text'
                    ? `${demogField.name} (${t('general.caseSensitive')})`
                    : demogField.name
                }
                type={demogField.format.toLowerCase() as 'date' | 'text' | 'number'}
                value={data[demogField.id]}
                onChange={(value: string | number | boolean) => handleFormChange(value, demogField)}
              />
            </Fragment>
          )
        })}
        <input
          className="mt-16"
          type="submit"
          name="submit"
          value={t('enterDemogs.submitText')}
          disabled={formInvalid}
        />
      </form>
    </div>
  )
}

export default RecipientSearchForm
