import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";

import "./styles/global.scss";
import "./styles/index.scss";
import "@pxhub/shared-components-v2/css/Toast.scss";

import App from "./App";
import ErrorBoundary from "./components/errorBoundary";
import MainError from "./components/errorBoundary/MainError";

import "./i18n";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById("root")!);

root.render(
  <ErrorBoundary fallback={(error: Error | undefined) => <MainError error={error} />}>
    <App />
  </ErrorBoundary>
);
