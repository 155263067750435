import {Input} from '@pxhub/shared-components'
import React, {useState} from 'react'

interface IProps {
  id: string
  labelText: string
  type: 'date' | 'text' | 'number'

  value: number
  onChange: any
}
const DemogInputSearch: React.FC<IProps> = ({id, labelText, type, value, onChange}: IProps) => {
  const [valid, setValid] = useState<boolean | undefined>(undefined)

  const handleChange = (value: string | number | boolean) => {
    if (typeof value === 'string' && type === 'text') {
      // const isValid = stringSchema.isValidSync({ value });
      const isValid = !!value.length
      setValid(isValid)
    }

    onChange(value)
  }
  return (
    <>
      <Input
        id={id}
        labelText={labelText}
        type={type}
        value={value}
        onChange={value => handleChange(value)}
        invalid={typeof valid !== 'undefined' ? !valid : undefined}
      />
    </>
  )
}

export default DemogInputSearch
