import React, { Component, ErrorInfo } from "react";

interface Props {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override render() {
    const { children, fallback } = this.props;

    if (this.state.error) {
      if (fallback) {
        return typeof fallback === "function" ? fallback(this.state.error) : fallback;
      }

      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
