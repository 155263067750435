import React, { Fragment } from "react";
import { Toast, ToastContainer } from "@pxhub/shared-components-v2";

import { IToast } from "@pxhub/shared-components-v2/dist/Toast";
import useInterval from "../hooks/useInterval";

interface IProps {
  toast: IToast[] | null;
  setToast: (arg: IToast[] | null) => void;
}

const ToastView: React.FC<IProps> = ({ toast, setToast }: IProps) => {
  useInterval(
    () => {
      setToast(null);
    },
    toast && toast.length >= 1 ? 10000 : null
  );

  const clearToast = (pos: number) => {
    setToast(toast && toast.filter((item: any, index: number) => index !== pos));
  };

  return (
    <Fragment>
      <ToastContainer>
        {toast &&
          toast.length >= 1 &&
          toast.map((item: IToast, i: number) => {
            return <Toast key={i} message={item.message} type={item.type} timeout={item.timeout} closeHandler={() => clearToast(i)} />;
          })}
      </ToastContainer>
    </Fragment>
  );
};

export default ToastView;
