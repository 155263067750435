import React from "react";

const GlobalLoading = () => {
  return (
    <div className="loading__screen global__loading">
      <div className="load-dot-container">
        <div className="load-dot-1"></div>
        <div className="load-dot-2"></div>
        <div className="load-dot-3"></div>
      </div>
    </div>
  );
};

export default GlobalLoading;
