import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useParams, useRoutes } from "react-router-dom";
import Logo from "../../images/px_logo.png";

interface IProps {
  error?: Error;
}

const MainError: React.FC<IProps> = ({ error }: IProps) => {
  const handleReturn = () => {
    // persister.purge()
    window.location.href = `${process.env.NX__LOGIN_REDIRECT}`;
  };

  const reportError = async (error: Error) => {
    const userAgent = navigator.userAgent;
    const language = navigator.language;

    process.env.NODE_ENV === "production" &&
      (await axios.post(`https://survey.pxhub.io/report`, {
        errors: [
          { message: "🚨 APP FRONT END 🚨" },
          { message: JSON.stringify(error, ["message", "arguments", "type", "name"]) },
          { message: `path: ${window.location.href}` },
          { message: `userAgent: ${userAgent}` },
          { message: `language: ${language}` },
        ],
      }));
  };

  useEffect(() => {
    if (error) reportError(error);
  }, []);

  return (
    <div className="error__boundary">
      <div className="flex__column__container">
        <div className="header">
          <img src={Logo} alt="The People Experience Hub" />
        </div>
        <div className="container">
          <h1>We&apos;re Sorry,</h1>
          <p>There appears to be an unexpected error, if this problem persists, please contact our support team</p>
          <button onClick={handleReturn}>Take me home</button>
        </div>
      </div>
    </div>
  );
};

export default MainError;
