import React, {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import CreateSurveySchema from '../../validators/SurveyCodeValidator'
import {MagnifyingGlass} from 'phosphor-react'
import {useTranslation} from 'react-i18next'

export const EnterSurveyCode = () => {
  const [inputCode, setInputCode] = useState<string>('')
  const [valid, setValid] = useState<boolean | null>()
  const {t, i18n} = useTranslation()

  const navigate = useNavigate()

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (inputCode.length) {
      e.preventDefault()
      navigate(`/${inputCode}`)
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setInputCode(e.target.value)
    const isValid = await CreateSurveySchema.isValid({inputCode: e.target.value})
    setValid(isValid)
  }

  return (
    <div className="inner__container">
      <div className="col col-no-gutter mb-24">
        <div className="row center-xs mb-8">
          <h1>{t('surveyCode.heading')}</h1>
        </div>
        <div className="row center-xs mb-24">
          <p style={{textAlign: 'center'}}>{t('surveyCode.subheading')}</p>
        </div>
      </div>
      <div className="form__wrapper">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="survey-code">{t('surveyCode.enterSurveyCodeLabel')}</label>
          <input
            className={valid === false ? 'input error mt-4' : 'input mt-4'}
            type="text"
            id="survey-code"
            placeholder={t('surveyCode.enterSurveyCodePlaceholder')}
            value={inputCode}
            onChange={handleChange}
          />
          <div className="input__container mt-24">
            <input data-testid="code-submit" type="submit" disabled={valid !== true} value="" />
            <div className="text">
              <MagnifyingGlass weight="bold" />
              <p>{t('general.findButton')}</p>
            </div>
          </div>
        </form>
        <div className="form__footer mt-16">
          <p className="small">
            {t('surveyCode.helpMessageOne')} <a href="mailto:help@pxhub.io">{t('surveyCode.helpMessageTwo')}</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EnterSurveyCode
